import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Master from '../components/Master'

const ComoFunciona = () => {
    return (
        <Master title={`Página não encontrada`}>
            <GatsbySeo
                title={`Página não encontrada`}
                description={`Página não encontrada`}
            />
            <h1>{ `Página não encontrada` }</h1>
            <p>A página pesquisa não foi enontrada.</p>
        </Master>
    )
}

export default ComoFunciona